import React from 'react';
import "./empowering.css"

const Empowering = () => {
  return (
    <div className='Empowering'>
        <div className='insideEmpowering'>
            <img className='empowering_img' src={require("../../../assets/new/aboutus/About us -banner@4x-100.jpg")}/>
        </div>
    </div>
  )
}

export default Empowering;