import React from 'react';
import "./ourpromise.css"
import Navbar from '../../components/Navbar/Navbar';
import Ourpromise from '../../components/OurPromise/Ourpromise';
import Footer from '../../components/Footer/Footer';

const OurPromise = () => {
  return (
    <>
      <Ourpromise/>
      <Footer/>
    </>
  )
}

export default OurPromise;